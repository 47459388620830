import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { useDebouncedCallback } from 'use-debounce';
import { RiSearchLine } from 'react-icons/ri';
import { SortSelect, InviteOptions } from './components';
import { ExperienceFilter, ExperienceSort } from '@/webapi/use-experience-api';
import { MyExperiencesContext } from '@/features/dashboard/experiences/context';
import { isBrowser } from '@/utils/browser';
import { CheckboxPopover } from '@/components/checkbox-popover';
import { breakpoints } from '@/components/responsive';
import { FilterChip } from '@/components/filter-chip';
import { SearchInput } from '@/components/searchInput';

export function ExperienceStatusFilter() {
  const {
    filter,
    sort,
    searchText,
    setFilter,
    setSort,
    setSearchText,
    draft,
    abTests,
    oneHundredPercent,
    running,
    archived,
    paused,
    total,
    labelsState,
    setLabelsState,
    labelsGetter,
  } = useContext(MyExperiencesContext);

  const mobileInputRef = useRef<HTMLInputElement>(null);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);

  const onMobileSearchClicked = () => {
    setIsMobileSearchOpen(true);
  };

  const onSelection = (value: ExperienceFilter) => {
    setFilter(value);
  };

  const onSearchTextChanged = (ev) => {
    const text = ev.target.value as string;
    setSearchText(text);
  };

  const onSortChanged = (sort: ExperienceSort) => {
    setSort(sort);
  };

  const debouncedSearch = useDebouncedCallback(onSearchTextChanged, 300);

  const selectedLabels = useMemo(
    () => labelsState?.filter((l) => l.selected),
    [labelsState],
  );

  const labelsCaption = useMemo(() => {
    if (!selectedLabels || selectedLabels?.length === 0) {
      return `By Label`;
    }

    if (selectedLabels?.length === 1) {
      return selectedLabels[0]?.caption;
    }

    return `${selectedLabels?.length} labels`;
  }, [selectedLabels, labelsState]);

  useEffect(() => {
    if (isMobileSearchOpen) {
      setTimeout(
        () => mobileInputRef?.current?.querySelector(`input`)?.focus(),
        500,
      );
    }
  }, [isMobileSearchOpen]);

  if (!isBrowser()) {
    return null;
  }

  return (
    <>
      <Wrapper>
        <FilterChip
          onClick={() => {
            total > 0 && onSelection(ExperienceFilter.UNKNOWN_STATUS);
          }}
          vselected={filter === ExperienceFilter.UNKNOWN_STATUS}
        >
          All ({running + draft + paused})
        </FilterChip>
        <FilterChip
          vdisabled={running === 0}
          onClick={() => {
            running > 0 && onSelection(ExperienceFilter.PUBLISHED);
          }}
          vselected={filter === ExperienceFilter.PUBLISHED}
        >
          Live ({running})
        </FilterChip>
        <FilterChip
          vdisabled={abTests === 0}
          onClick={() => {
            abTests > 0 && onSelection(ExperienceFilter.AB_TEST);
          }}
          vselected={filter === ExperienceFilter.AB_TEST}
        >
          A/B Test ({abTests})
        </FilterChip>
        <FilterChip
          vdisabled={oneHundredPercent === 0}
          onClick={() => {
            oneHundredPercent > 0 &&
              onSelection(ExperienceFilter.ONE_HUNDRED_PERCENT);
          }}
          vselected={filter === ExperienceFilter.ONE_HUNDRED_PERCENT}
        >
          100% ({oneHundredPercent})
        </FilterChip>
        <FilterChip
          vdisabled={draft === 0}
          onClick={() => {
            draft > 0 && onSelection(ExperienceFilter.DRAFT);
          }}
          vselected={filter === ExperienceFilter.DRAFT}
        >
          Draft ({draft})
        </FilterChip>
        <FilterChip
          vdisabled={paused === 0}
          onClick={() => {
            paused > 0 && onSelection(ExperienceFilter.PAUSED);
          }}
          vselected={filter === ExperienceFilter.PAUSED}
        >
          Paused ({paused})
        </FilterChip>
        <FilterChip
          vdisabled={archived === 0}
          onClick={() => {
            archived > 0 && onSelection(ExperienceFilter.ARCHIVED);
          }}
          vselected={filter === ExperienceFilter.ARCHIVED}
        >
          Archived ({archived})
        </FilterChip>
        <CheckboxPopover
          options={labelsState}
          optionsGetter={labelsGetter}
          emptyState="No labels found"
          onChange={(state) => setLabelsState(state)}
        >
          <FilterChip
            className={`label-select ${
              selectedLabels?.length > 0 ? `selected` : ``
            }`}
          >
            <span className="long-text">{labelsCaption}</span>
            {` `}
            <VscFilter size={14} />
          </FilterChip>
        </CheckboxPopover>
        <SearchInput
          className="only-desktop"
          defaultValue={searchText}
          grow
          onChange={debouncedSearch}
        />
        <SortSelect defaultValue={sort} onSelection={onSortChanged} />
        <InviteOptions />
      </Wrapper>
      <MobileSearchWrapper
        ref={mobileInputRef}
        className={`only-mobile ${isMobileSearchOpen ? `open` : ``}`}
      >
        {!isMobileSearchOpen && (
          <span onClick={onMobileSearchClicked}>
            <RiSearchLine size={24} />
          </span>
        )}
        {isMobileSearchOpen && (
          <SearchInput
            className="only-mobile"
            defaultValue={searchText}
            onChange={debouncedSearch}
            onInputBlur={() => setIsMobileSearchOpen(false)}
          />
        )}
      </MobileSearchWrapper>
    </>
  );
}

const Wrapper = styled.div`
  user-select: none;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 0.3rem;

  input {
    font-size: 1.3rem;
  }

  select {
    font-size: 1.3rem;
  }

  ${breakpoints.down(`md`)} {
    max-width: 100vw;
    overflow-x: scroll;

    .invite-wrapper,
    .sort-select,
    .label-select {
      display: none;
    }

    padding: 0 8rem 0 2.5rem;

    && > * {
      flex: 0 0 auto;
    }
  }
`;

const MobileSearchWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  color: #8f9daa;
  background: rgb(248, 249, 251);
  width: 5.5rem;
  height: 4rem;
  border-left: 2px solid #f0f0f0;
  flex-direction: column;

  -webkit-box-shadow: -2px 0px 34px 25px rgba(248, 249, 251, 1);
  -moz-box-shadow: -2px 0px 34px 25px rgba(248, 249, 251, 1);
  box-shadow: -2px 0px 34px 25px rgba(248, 249, 251, 1);

  &&.open {
    width: 100% !important;
  }

  input {
    width: 100% !important;
  }
`;
