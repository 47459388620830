import React, { useContext, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import styled from 'styled-components';
import {
  SharedElementOverlay,
  SharedElementOverlayProps,
} from '@/components/shared-element-overlay';
import {
  ExperienceVariant,
  useExperienceApi,
  VariantPreview,
} from '@/webapi/use-experience-api';
import { openLinkInNewTab } from '@/utils/browser';
import { AccountContext } from '@/features/account-context';
import { PreviewPopoverContentNew } from '@/features/editor/widgets/shared/preview-popover-new';
import { awaitCondition } from '@/utils/sync';

export interface PermanentPreviewModalProps {
  overlayProps: SharedElementOverlayProps;
  experienceId: string;
  themeId: number;
  mainThemeId: number;
  hide: () => void;
}

export function PermanentPreviewModal({
  experienceId,
  themeId,
  mainThemeId,
  overlayProps,
  hide,
}: PermanentPreviewModalProps) {
  const {
    account: {
      store: { domain },
    },
  } = useContext(AccountContext);

  const { generatePermanentPreviewLinks, loading } = useExperienceApi(
    undefined,
    undefined,
    true,
  );

  const [links, setLinks] = useState([]);

  useAsyncEffect(async () => {
    const result = await generatePermanentPreviewLinks(
      experienceId,
      domain,
      themeId,
      mainThemeId,
    );
    if (result?.links?.length === 1) {
      openPreviewLink(result.links[0]);
    }
    setLinks(result?.links);
  }, []);

  const openPreviewLink = (item: VariantPreview) => {
    const url = new URL(item.link);
    url.searchParams.delete(`preview_theme_id`);
    openLinkInNewTab(url.toString(), item.id);
    hide();
  };

  const onPreview = async (
    baseUrl: string,
    variantId: string,
  ): Promise<string> => {
    await awaitCondition(() => links?.length > 0);
    return Promise.resolve(
      links?.find((link) => link.id === variantId)?.link || links?.[0]?.link,
    );
  };

  return (
    <SharedElementOverlay {...overlayProps} heightMultiplier={1}>
      <Wrapper>
        {!loading && links?.length > 0 && (
          <PreviewPopoverContentNew
            onPreview={onPreview}
            onSharePreview={() => Promise.resolve(``)}
            quickPreviewUrl={domain}
            togglePopover={hide}
            variants={
              links?.map((link) => ({
                id: link.id,
                name: link.name,
              })) as ExperienceVariant[]
            }
          />
        )}
      </Wrapper>
    </SharedElementOverlay>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  && > * {
    box-shadow: none;
    border-radius: 0;
    border: none;
  }
`;
