import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { openNewTab } from '@/utils/browser';
import { CRO_EXPERT_LINK } from '@/features/shop-settings/pricing-plans-card';

export function BookDemoCard({ width }) {
  const onClick = () => {
    openNewTab(CRO_EXPERT_LINK, `vsly-demo`);
  };

  return (
    <Wrapper id="demo-card" width={width}>
      <StaticImage
        src="./tv.png"
        alt="tv"
        placeholder="none"
        loading="eager"
        width={70}
        objectFit="contain"
      />
      <Text>
        Customers who connect with our CRO experts see better results - get a
        personalized walkthrough,
        <br />
        learn the tips other customers are using to succeed, and discover
        strategies to maximize your success with
        <br />
        Visually! We want you to succeed, so this call is on us!
      </Text>
      <CTA onClick={onClick}>Connect with a CRO Expert</CTA>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: ${(p) => p.width || `70%`};
  display: grid;
  grid-template-columns: auto;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 4rem;

  border-radius: 1.8rem;
  background-color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15),
    0 7px 13px 0 rgba(90, 201, 172, 0.12);
`;

const Text = styled.span`
  color: black;
  font-family: 'Eesti', serif;
  font-size: 1.7rem;
  text-transform: none;
  line-height: 3.5rem;
  font-weight: 500;
  letter-spacing: -0.7px;
  text-align: center;
`;

const CTA = styled.button`
  appearance: none;
  outline: none;
  border: none;
  border-radius: 2rem;
  background-color: black;
  color: #eceef6;
  padding: 1rem 2.5rem;
  font-family: Eesti, serif;
  font-size: 1.4rem;
  font-weight: bold;
  width: fit-content;

  cursor: pointer;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;
