import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import ReactTooltip from 'react-tooltip';
import {
  ExperienceSort,
  sortByCreationDateAsc,
  sortByCreationDateDesc,
  sortByLastUpdatedAsc,
  sortByLastUpdatedDesc,
  sortByNameDateAsc,
  sortByNameDateDesc,
  sortByPublishDateAsc,
  sortByPublishDateDesc,
} from '@/webapi/use-experience-api';
import { useListUsers } from '@/features/teammates/useListUsers';
import { Avatar } from '@/components/avatar';
import { firstLetters } from '@/components/header';
import { UserProps } from '@/features/teammates/types';
import {
  AccountContext,
  AccountContextProps,
} from '@/features/account-context';
import { useAddUserModalProps } from '@/features/teammates/AddUserPopOver';
import { AddUserModalSmall } from '@/features/teammates/AddUserModalSmall';
import { nav } from '@/utils/browser';
import { Pages } from '@/webapi/pages';
import { isAdmin } from '@/utils/rbac-utils';

export function SortSelect({
  defaultValue,
  onSelection,
}: {
  defaultValue?: ExperienceSort;
  onSelection?: (ExperienceSort) => void;
}) {
  const options = [
    { name: `Publish Date (New-Old)`, value: sortByPublishDateDesc },
    { name: `Publish Date (Old-New)`, value: sortByPublishDateAsc },
    { name: `Creation Date (New-Old)`, value: sortByCreationDateDesc },
    { name: `Creation Date (Old-New)`, value: sortByCreationDateAsc },
    { name: `Update Date (New-Old)`, value: sortByLastUpdatedDesc },
    { name: `Update Date (Old-New)`, value: sortByLastUpdatedAsc },
    { name: `Name (A-Z)`, value: sortByNameDateAsc },
    { name: `Name (Z-A)`, value: sortByNameDateDesc },
  ];

  const onChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    if (onSelection) {
      const idx = parseInt(ev.target.value, 10);
      onSelection(options[idx].value);
    }
  };

  return (
    <SortSelectWrapper className="sort-select">
      <StyledSelect onChange={onChange}>
        {options.map((opt, idx) => (
          <option
            key={opt.name}
            value={idx}
            selected={
              JSON.stringify(opt.value) === JSON.stringify(defaultValue)
            }
          >
            {opt.name}
          </option>
        ))}
      </StyledSelect>
      <DownIcon />
    </SortSelectWrapper>
  );
}

const SortSelectWrapper = styled.div`
  position: relative;

  .gatsby-image-wrapper {
    position: absolute;
    right: 1.8rem;
    top: calc(50% - 6.5px);
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 100%;
  outline: none;
  appearance: none;
  border: none;
  text-transform: capitalize;

  -webkit-appearance: none;
  pointer-events: auto;

  background: transparent;
  cursor: pointer;
  font-size: 1.4rem;
  font-weight: normal;
  font-family: 'Eesti', serif;

  color: #8f9daa;

  border-radius: 2rem;

  padding: 0 3.2rem 0 1.5rem;

  && > * {
    text-transform: capitalize;
  }
`;

function DownIcon() {
  return (
    <StaticImage
      src="./sort.svg"
      alt="end"
      placeholder="none"
      loading="eager"
      height={12}
    />
  );
}

export function InviteOptions() {
  const { account } = useContext(AccountContext);
  const [show, setShow] = useState(false);
  const { listUsers } = useListUsers();
  const { props, hide } = useAddUserModalProps();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    listUsers(0, 99999).then((result) => setUsers(result?.users || []));
  }, []);

  const isCurrentUserAdmin = useMemo(
    () => isAdmin(account?.store?.role),
    [account],
  );

  const onMoreClicked = () => {
    if (isCurrentUserAdmin) {
      nav(Pages.TEAMMATES);
    }
  };

  const onInviteClicked = () => {
    if (isCurrentUserAdmin) {
      setShow((prev) => !prev);
    }
  };

  return (
    users?.length > 0 && (
      <InviteOptionsWrapper className="invite-wrapper">
        {users.length > 3 && (
          <>
            <MoreUsers
              data-tip
              data-for="tooltip_all_names"
              onClick={onMoreClicked}
            >
              +{users.length - 3}
            </MoreUsers>
            <ReactTooltip
              id="tooltip_all_names"
              place="bottom"
              backgroundColor="#fff"
              textColor="#000"
              className="text-tooltip"
            >
              <NamesContainer>
                {users
                  .slice(0, -3)
                  .map((user) => `${user.firstName} ${user.lastName}`)
                  .join(`\n`)}
              </NamesContainer>
            </ReactTooltip>
          </>
        )}
        <UsersWrapper>
          {users.slice(-3).map((user, i) => (
            <TooltipWrapper
              data-tip={user.firstName}
              data-for={`tooltip_name-${i}`}
              key={i}
              style={{ zIndex: 3 - i }}
            >
              <Avatar
                wrapperStyles={
                  i === 1
                    ? {
                        background: `#E6F4FF`,
                        border: `1px #7FA9D080 solid`,
                      }
                    : {
                        background: `#EAECEE`,
                        border: `1px #a6afb880 solid`,
                      }
                }
                size="2.9rem"
              >
                <Accronims index={i}>{firstLetters(param(user))}</Accronims>
              </Avatar>
              <ReactTooltip
                id={`tooltip_name-${i}`}
                place="bottom"
                backgroundColor="#fff"
                textColor="#000"
                className="text-tooltip"
              >
                <p>{`${user.firstName} ${user.lastName}`}</p>
              </ReactTooltip>
            </TooltipWrapper>
          ))}
        </UsersWrapper>
        {isCurrentUserAdmin && (
          <InviteBlockWrapper>
            <InviteButton onClick={onInviteClicked}>
              <svg
                width="30px"
                height="34px"
                viewBox="0 0 30 34"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <title>user-plus-alt-1-svgrepo-com</title>
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <g
                    id="New-Dashboard---last-design-Copy-2"
                    transform="translate(-3160, -410)"
                    stroke="#000000"
                    strokeWidth="3.2"
                  >
                    <g
                      id="user-plus-alt-1-svgrepo-com"
                      transform="translate(3162, 412)"
                    >
                      <path
                        d="M26,24.1666667 L16.625,24.1666667 M21.3125,19.3333333 L21.3125,29 M0,30 C0,23.7714444 4.89689063,18.7222222 10.9375,18.7222222 C12.0234375,18.7222222 13.0723438,18.8854278 14.0625,19.1892833 M17.1875,6.44444444 C17.1875,10.0036144 14.3892187,12.8888889 10.9375,12.8888889 C7.48571875,12.8888889 4.6875,10.0036144 4.6875,6.44444444 C4.6875,2.88527444 7.48571875,0 10.9375,0 C14.3892187,0 17.1875,2.88527444 17.1875,6.44444444 Z"
                        id="Shape"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </InviteButton>
            <AddUserModalSmall
              hide={hide}
              /* eslint-disable-next-line @typescript-eslint/no-empty-function */
              refreshUserList={() => {}}
              {...props}
              show={show}
              setShow={setShow}
            />
          </InviteBlockWrapper>
        )}
      </InviteOptionsWrapper>
    )
  );
}

const InviteOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const InviteBlockWrapper = styled.div`
  position: relative;
`;
const UsersWrapper = styled.div`
  display: flex;
  z-index: 3;
  flex-direction: row;

  && > div {
    margin-left: -7px;
  }
`;

const Accronims = styled.p`
  color: ${({ index }: any) => (index === 1 ? `#7FA9D0` : `#a6afb8`)};
  font-size: 1.2rem;
  font-weight: 400;
`;

function param(user: UserProps) {
  return {
    account: {
      store: {
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
    },
  } as AccountContextProps;
}

const MoreUsers = styled.div`
  background: white;
  color: #a6afb8;
  border-radius: 50%;

  text-align: center;
  height: 2.9rem;
  width: 2.9rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  border: 1px rgba(166, 175, 184, 0.5) solid;
  font-weight: 400;
  z-index: 4;
  align-items: center;

  &&:hover {
    cursor: pointer;
    background: #fcfcfc;
  }
`;

const InviteButton = styled.div`
  background: white;
  color: #a6afb8;
  border-radius: 50%;

  text-align: center;
  height: 2.9rem;
  width: 2.9rem;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  border: 1px rgba(166, 175, 184, 0.5) solid;
  font-weight: 400;
  align-items: center;
  opacity: 1;
  margin-left: 12px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 2px 15px 0;

  &&:hover {
    cursor: pointer;
    opacity: 0.7;
    border-color: #63676c;
  }

  && > svg {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const NamesContainer = styled.div`
  white-space: pre-line;
  text-align: left;
  line-height: 2rem;
`;
const TooltipWrapper = styled.div`
  position: relative;
  && > div[data-id='tooltip'] {
    padding: 0 1rem !important;
  }
`;
